@use '@publicismedia-ds/ui-theme' as theme;

.new-group-container {
    margin-top: 2rem;
    min-width: 500px;
    height: fit-content;
    max-height: 80%;
    padding: 1rem;

    .new-group-header {
        h2 {
            font-size: 1.5rem;
            margin: 0;
            text-transform: capitalize;
        }
    }

    .new-group-content {
        form {
            margin-top: 1.5rem;

            .new-group-item-field-container {
                width: 90%;
                margin: 0 auto;

                .error-message {
                    margin-top: 1rem;
                    padding: 0.5rem 0;
                    background-color: pink;
                    color: red;
                }

                .add-new-group-item-inputs {
                    display: flex;
                    align-items: baseline;
                    width: fit-content;
                    margin: 0 auto;
                    button {
                        margin: 0.2rem 0;
                        border-color: rgb(0, 140, 0);
                        background-color: rgb(0, 140, 0);

                        &:hover {
                            opacity: 0.95;
                        }
                    }
                    .new-group-item-input {
                        margin: 0.5rem;
                        margin-left: 0;
                    }
                }

                .new-group-item {
                    font-size: 0.9rem;
                    display: inline-block;
                    min-width: fit-content;
                    padding: 0.4rem 0.8rem;
                    margin-right: 0.5rem;
                    margin-top: 0.5rem;
                    background-color: #ffffffbb;
                    border: 2px solid theme.$color_brand-1;
                    border-radius: 2rem;

                    .remove-new-group-item {
                        display: inline-block;
                        margin-right: 0.4rem;
                        padding: 0.2rem 0.2rem 0.1rem 0.2rem;
                        font-size: 0.95rem;
                        color: theme.$color_brand-1-darker;

                        &:hover {
                            cursor: pointer;
                        }
                    }
                }
            }

            .new-group-item-field {
                margin-top: 0.5rem;
                border: 1px solid lightblue;
                border-radius: 0.4rem;
                min-width: 40%;
                height: 10rem;
                overflow: auto;

                .added-group-items-content {
                    display: flex;
                    flex-wrap: wrap;
                    // justify-content: space-evenly;
                    gap: 0.5rem;
                    padding: 0.5rem;
                }
            }

            .pmds-fs {
                align-items: center;
                padding: 0.5rem;
                overflow-y: auto;
                height: fit-content;
                min-height: 10rem;
                max-height: 10rem;
                margin: 0;
            }
        }
    }
    .new-group-footer {
        align-self: baseline;
    }
}
