.view-alert-container {
    .view-alert-header {
        display: flex;
        justify-content: center;
        align-items: baseline;
    }

    .pmds-dd__multi-values {
        .pmds-dd__multi-values-clear {
            display: none;
        }
    }
}
