@use '@publicismedia-ds/ui-theme' as theme;

.custom-tooltip-container {
    width: 100%;

    .custom-tooltip {
        .tooltip-icon {
            font-size: 0.8rem;
            color: theme.$color-brand-1-darker;
            margin-left: 0.2rem;
            transform: translateY(-2px);
            cursor: help !important;
        }

        &:hover {
            cursor: help;

            .pmds-tooltip__title {
                display: block;
            }

            .tooltip-icon {
                color: theme.$color-brand-1-lighter;
            }
        }

        .custom-tooltip-label {
            display: flex;
            align-items: baseline;
            gap: 0.125rem;
        }

        .pmds-tooltip__title {
            display: none;
            z-index: 100000000;
            position: absolute;
            font-size: 0.8rem;
            line-height: 1.125rem;

            p {
                margin: 0.5rem 0;
                font-size: inherit;
            }
        }
    }
}
