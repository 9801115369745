.brand-benchmarking-container {
    .keyword-overlap-chart-container {
        .keyword-overlap-chart-header {
            margin-top: 2rem;
            transform: translateY(8px);

            .chart-title {
                padding-left: 0.25rem;
                font-size: 1.2rem;
                font-weight: 500;
            }
        }
    }
    .pmds-pagination__wrap {
        visibility: hidden;
    }
}
