.insights-filter-container {
  margin-top: 1.25rem;
  .insights-filter-header {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
    font-style: italic;

    p {
      font-weight: bold;
    }

    ul {
      padding-top: 0.25rem;
      padding-left: 1rem;
      list-style-type: disc;
    }
  }
  .insights-filter-button {
    margin: 1rem 0;
    text-align: center;
  }
}
