.chart-wrapper-container {
  .chart-wrapper-header {
    position: relative;
    display: flex;
    justify-content: space-between;

    .chart-wrapper-header-text {
      font-size: 1.3rem;
      font-weight: 500;
      // text-align: center;
      // position: relative;

      .custom-tooltip-container {
        min-height: 16px;
      }
    }

    .chart-wrapper-header-sub {
      font-size: 0.8rem;
      font-style: italic;
      color: #666;
      transform: translateY(2px);
    }

    .chart-wrapper-header-dd {
      // position: absolute;
      // top: -1px;
      // left: 0;
      // text-align: left;

      .pmds-dd {
        label {
          display: none;
        }
      }
    }
  }
}
