.top-ad-item-container {
  border-bottom: 1px solid #e9e9e9;
  padding: 1rem;

  .sponsored {
    background-color: #fff;
    border-radius: 3px;
    margin-right: 8px;
    line-height: 20px;
    background-color: transparent;
    color: #202124;
    display: inline-block;
    font-family: inherit;
    font-size: 0.85rem;
    font-weight: bold;
    margin-left: 0px;
    white-space: nowrap;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  .link-wrapper {
    position: relative;
    display: inline-block;
    min-width: 0;
    margin-bottom: 0.25rem;

    .link-item-2-wrapper {
      padding-top: 2px;
      color: #4d5156;
      font-size: 13px;
      white-space: nowrap;
      overflow: hidden;
    }
    .svg-kebob {
      height: '16px';
      line-height: '16px';
      width: '16px';
      position: 'absolute';
      top: '2px';
      right: '-12px';
    }
  }

  .top-ad-header {
    position: relative;

    .container {
      align-items: center;
      display: inline-flex;
      width: 100%;
      overflow: hidden;
      align-items: baseline;

      .inner-1 {
        line-height: normal;
        display: flex;

        .inner-2 {
          position: relative;
          background-color: #e2eeff;
          border: 1px solid #e2eeff;
          border-radius: 50%;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          height: 26px;
          width: 26px;
          margin-right: 12px;
          vertical-align: middle;

          .inner-div {
            color: #1a0dab;
            cursor: pointer;
            display: block;
            font-size: 14px;
            height: 18px;
            line-height: normal;
            margin-right: 0;
            text-decoration-thickness: normal;
            width: 18;
            svg {
              color: #0060f0;
              display: inline-block;
              fill: currentColor;
              height: 18px;
              line-height: 18px;
              position: relative;
              width: 18px;
              overflow-clip-margin: content-box;
              overflow-x: hidden;
              font-size: 14px;
            }
          }
        }

        .link-wrapper {
          display: inline-flex;
          align-items: flex-end;
          min-width: 0;
          font-size: 0.85rem;

          .link-item-2-wrapper {
            padding-top: 2px;
            color: #4d5156;
            font-size: inherit;
            white-space: nowrap;
            overflow: hidden;
            align-self: bottom;
            text-overflow: ellipsis;
          }

          .svg-kebob {
            height: 16px;
            line-height: 16px;
            width: 16px;
            position: absolute;
            top: 2px;
            right: -12px;

            svg {
              fill: #4d5156;
            }
          }
        }
      }
    }

    .ad-title {
      display: block;
      text-decoration: none;
      color: #1a0dab !important;
      text-decoration: none;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0.1);
      color: -webkit-link;
      text-decoration: none;
      font-family: Roboto, arial, sans-serif;
      clear: both;
      margin: 0;
      box-sizing: border-box;
      font-size: 1.125rem;
      line-height: 1.1rem;
    }

    .link {
      color: #4d5156;
      font-size: 12px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: 18px;
      max-height: 18px;
      font-family: Roboto, arial, sans-serif;
    }
  }

  .top-ad-description {
    padding-top: 4px;
    .description {
      color: #4d5156;
      margin-top: 4px;
      font-size: 0.85rem;

      .ad-marker {
        opacity: 0.95;
        border: 1px solid #c9c9c9;
        border-radius: 1rem;
        padding: 0 4px;
        margin-right: 2px;
      }
    }
  }
}
