.study-view-container {
    .study-view-header {
        text-align: center;
        font-size: 2rem;
        margin-bottom: 1rem;
    }

    .study-view-tab-group {
        width: 100%;
    }
}
