.scheduled-reports-table {
    margin-top: 1.125rem;
    .pmds-dt__above {
        margin: 0;
    }
    .data-table-above {
        width: 100%;
        display: flex;
        margin: 0;
        justify-content: space-between;
        align-items: flex-end;

        .data-table-action-buttons {
            display: flex;
            gap: 1rem;

            .pmds-btn {
                margin: 0;
            }
        }

        input {
            margin: 0;
        }
    }

    .pmds-dt {
        .pmds-dt__wrap {
            .pmds-dt-table {
                max-height: 99%;
            }
        }

        .pmds-dd {
            min-width: 120px;
        }

        .pmds-dt-td {
            .pmds-dt-td__children {
                button.data-table-button {
                    visibility: visible;
                }
            }
        }

        button.data-table-button {
            visibility: visible;
            border: none;
            font-size: 0.9rem;
            background: none;
            text-decoration: underline;
            color: blue;

            &:hover {
                cursor: pointer;
            }
        }
    }

    .pmds-dt-tr {
        &:hover {
            .recipients-column {
                i {
                    visibility: visible;
                }
            }
        }
        .recipients-column {
            i {
                visibility: hidden;
                color: #0ebcff;
                padding-left: 0.5rem;

                &:hover {
                    cursor: pointer;
                }
            }
        }
    }
}
