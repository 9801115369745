.forgot-password-container {
    width: 60%;
    margin: 0 auto;
    padding: 2rem;
    text-align: center;

    .forgot-password-header {
        text-align: center;
        margin-bottom: 2rem;

        h1 {
            font-size: 2rem;
        }
    }

    .forgot-password-content {
        text-align: center;
        p {
            font-style: italic;
        }

        label {
            color: black;
        }

        .forgot-password-form {
            text-align: center;

            .pmds-txt {
                margin-right: 0;
                width: 100% !important;
            }

            .form-buttons {
                display: flex;
                justify-content: center;
                align-items: flex-end;
                margin-top: 2rem;
                font-weight: bold;

                a.form-button-login {
                    font-size: 1.1rem;
                    padding-bottom: 1rem;
                    margin-right: 1rem;
                }
            }
        }
    }
}
