@use '@publicismedia-ds/ui-theme' as theme;

.alert-form-container {
    .keywords-alert-time-period {
        width: 100%;
        margin-top: 0;

        .pmds-fs__children {
            width: 100%;
            justify-content: space-evenly;
        }
    }

    .keywords-alert-header {
        margin: 1.5rem 0 1rem 0;
        text-align: center;

        span {
            font-size: 0.9rem;
        }
    }
}
