@use '@publicismedia-ds/ui-theme' as theme;

.alert-message-container {
    width: 70%;
    margin: 0 auto;
    margin-top: 3rem;
    position: relative;
    border-radius: 2rem;
    padding: 1.2rem 0;
    display: none;
    transform: translateY(-2rem);

    &.display-alert {
        display: flex;
        justify-content: center;
    }

    &.alert-message-success {
        background-color: theme.$color_data-viz-7;
        color: darkgreen;
    }

    &.alert-message-error {
        background-color: pink;
        color: darkred;
    }

    .alert-message-dismiss {
        position: absolute;
        top: 50%;
        left: 3rem;
        transform: translate(-50%, -50%);
        padding: 1rem;
        font-size: 1rem;

        &:hover {
            cursor: pointer;
        }
    }

    .alert-message-content {
        max-width: 70%;
    }
}

@media only screen and (max-width: 1200px) {
    .alert-message-container {
        width: 60%;
    }
}
