.custom-apex-legend-container {
  margin-top: 10px;
  .custom-apex-legend-content {
    display: flex;
    justify-content: center;
    gap: 2rem;

    .custom-apex-legend-item {
      position: relative;
      font-size: 13px;

      &[data-active='false'] {
        opacity: 0.5;
        transition: opacity 0.15s;

        &:hover {
          opacity: 0.7;
        }
      }

      &:hover {
        cursor: pointer;
      }

      .custom-apex-legend-item__before {
        left: -16px;
        top: 1px;
        position: absolute;
        display: block;
        height: 12px;
        width: 12px;
        background-color: #2764b0;
        border-radius: 2px;
      }
    }
  }
}
