@use '@publicismedia-ds/ui-theme' as theme;

$classNameComponent: 'modal';
$classNamePrefix: $classNameComponent + '-';

$transition-time: 0.37s;

//Note this is purposly not wrapped in $classNameComponent because the modal gets added to the body and will not be nested inside $classNameComponent
.modal-container {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  width: 100%;
  height: 100%;
  text-align: center;

  .modal-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: theme.$color_modal-overlay-bg;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    transition: opacity $transition-time;
    opacity: 1;
  }

  .modal-frame {
    position: relative;
    top: calc(50% - 10px);
    left: calc(50%);
    display: block;
    background-color: theme.$color_modal-bg;
    box-sizing: content-box;
    box-shadow: 0 0 theme.mixin_space(4) #000000;
    text-align: left;
    transition: top $transition-time, left $transition-time,
      transform $transition-time * 0.5;
    outline: none;
    transform: scale(0.9);
    width: theme.$breakpoint_xs;
    width: fit-content;
    max-width: 90vw;
    max-height: 90vh;
    border-radius: theme.mixin_space(3);
    transform: translate(-50%, -50%);

    //Accessibility Section 508 and WCAG 2.0
    &:before {
      content: 'Dialog Start';
      display: inline-block;
      position: absolute;
      top: -99999px;
      left: -99999px;
      opacity: 0;
    }

    .modal-top-border {
      height: theme.mixin_space(4);
      width: 100%;
      top: 0;
      position: absolute;
      border-top-left-radius: theme.mixin_space(3);
      border-top-right-radius: theme.mixin_space(3);
      &-brand-1 {
        background-color: theme.$color_modal-border-primary;
      }
      &-brand-2 {
        background-color: theme.$color_modal-border-primary-2;
      }
      &-red {
        background-color: theme.$color_modal-border-primary-danger;
      }

      &.success {
        background-color: theme.$color_green;
      }

      &.error {
        background-color: theme.$color_modal-border-primary-danger;
      }
    }

    .modal-content-box {
      outline: none;
      color: theme.$color_modal-txt;
      display: flex;
      flex-direction: column;
      height: 100%;
      max-height: 90vh;

      .modal-heading {
        @include theme.font_futuraRegular;
        font-size: theme.mixin_toRem(30);
        line-height: theme.mixin_toRem(35);
        margin: theme.mixin_space(10) theme.mixin_space(10) theme.mixin_space(3)
          theme.mixin_space(10);
        color: theme.$color_modal-headline;
        text-transform: capitalize;
        text-align: center;
      }

      .modal-description {
        font-size: theme.mixin_toRem(14);
        line-height: theme.mixin_toRem(18);
        margin: 0 theme.mixin_space(10) theme.mixin_space(4)
          theme.mixin_space(10);

        &.modal-empty {
          margin: 0 theme.mixin_space(10) theme.mixin_space(10)
            theme.mixin_space(10);
        }
      }

      .modal-content {
        box-sizing: border-box;
        padding: 0 theme.mixin_space(10) theme.mixin_space(10)
          theme.mixin_space(10);
        overflow: auto;
        flex-grow: 1;
        font-size: 1rem;
        max-height: 60vh;

        & > *:first-child {
          margin-top: theme.mixin_space(2);
        }

        form > *:first-child {
          margin-top: 0;
        }

        //Default styles for elements in Modal
        p {
          font-size: theme.mixin_toRem(14);
          line-height: theme.mixin_toRem(18);
          margin: 0 0 theme.mixin_space(6) 0;
        }

        .pmds-txt {
          width: 100%;
          max-width: unset;
        }
      }

      .modal-footer {
        box-sizing: border-box;
        padding: theme.mixin_space(5) theme.mixin_space(10) theme.mixin_space(5)
          theme.mixin_space(9.5);
        background-color: theme.$color_modal-actions-bg;
        display: flex;
        flex-direction: row;
        border-radius: 0 0 theme.mixin_space(3) theme.mixin_space(3);
        justify-content: flex-end;

        .pmds-btn {
          margin-left: theme.mixin_space(3);
          margin-right: theme.mixin_space(3);

          &__secondary {
            background: transparent;
          }

          &:first-child {
            margin-left: 0;
          }
          &:last-child {
            margin-right: 0;
          }
        }

        .modal-actions-left,
        .modal-actions-right {
          display: flex;
          flex-grow: 1;
          justify-content: flex-start;

          & > * {
            margin-top: auto;
            margin-bottom: auto;
          }
        }
        .modal-actions-right {
          justify-content: flex-end;
        }

        .pmds-btn__red {
          & > * {
            color: white;
          }
        }
      }
    }

    .modal-close {
      position: absolute;
      right: theme.mixin_space(6);
      top: theme.mixin_space(6);
      text-indent: -99999px;
      border: 0;
      margin: 0;
      padding: 0;
      cursor: pointer;
      color: theme.$color_modal-icon-close;
      z-index: 10;

      &[disabled] {
        display: none;
      }

      &:focus:after {
        outline: 1px dotted theme.$color_modal-accent;
      }

      &:after {
        content: theme.$icon_close;
        display: block;
        font-family: theme.$icon_font;
        font-size: 1rem;
        line-height: 1rem;
        position: absolute;
        top: theme.mixin_space(-1);
        right: theme.mixin_space(-1);
        padding: theme.mixin_space(1);
        text-indent: 0;
      }
    }
  }

  &.modal-visible {
    .modal-background {
      opacity: 1;
    }

    .modal-frame {
      top: 0;
      left: 0;
      transform: scale(1);
    }
  }
}
