.top-ads-container {
  .top-ads-header {
    margin-bottom: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .top-ads-content {
    margin-top: 1.25rem;
    position: relative;
    min-width: 500px;
    height: 350px;
    border: 1px solid #e9e9e9;
    border-radius: 0.25rem;
    overflow: auto;

    .top-ads-no-data {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-family: Roboto, Arial, Helvetica, sans-serif;
      color: rgb(127, 127, 127);
      font-size: 2.5rem;
      font-weight: 400;
    }
  }
}
