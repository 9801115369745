.report-form-container {
    width: 100%;

    hr {
        width: 100%;
        margin: 1.5rem 0;
        border-color: #00000044;
    }

    .filters-title {
        margin: 1rem 0;

        span {
            margin-left: 0.25rem;
        }
    }

    .download-btn {
        margin: 0;
        height: 2.5rem;
        align-self: flex-end;
    }

    .form-buttons {
        display: flex;
        justify-content: center;
        margin-top: 3rem;
    }
}
