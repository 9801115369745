.landing-container {
  text-align: center;
  h1 {
    font-size: 4rem;
    margin-top: 2rem;
  }

  p {
    margin: 3rem 0 2rem 0;
  }

  .account-details-content {
    h2 {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 2rem;
    }
    .account-details-table {
      text-align: left;
      margin: 1rem auto;
      tr {
        td {
          padding: 1rem;
          min-width: 9rem;
          border: 1px solid black;

          &:last-child {
            text-align: center;
          }
        }
      }
    }
  }
}
