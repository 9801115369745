.study-detail-container {
    flex-grow: 1;
    text-align: center;

    .study-detail-header {
        text-transform: capitalize;
        margin: 1rem 0;
    }

    .study-detail-content {
        font-size: 1rem;
    }
}
