@use '@publicismedia-ds/ui-theme' as theme;

.nav-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;

  .logo {
    position: absolute;
    top: 50%;
    left: 0;
    &:hover {
      cursor: pointer;
    }
    height: fit-content;
    z-index: 1000;
    transform: translate(24px, -50%) scale(1.125);
  }
  nav.pmds-secondary-nav {
    top: 0px !important;
    min-height: 56px;

    ul.pmds-secondary-nav__children {
      padding-right: 2rem;
      margin-left: auto;
      li {
        div.pmds-nav-link {
          ul.pmds-nav-link__children {
            width: fit-content;
          }
        }
      }
    }
    .pmds-nav-link__text {
      font-size: 0.9rem;
    }
  }
}
