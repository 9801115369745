@use '@publicismedia-ds/ui-theme' as theme;

.custom-modal-container {
    position: relative;
    padding: 1.5rem 0.5rem 1.5rem 1.5rem;
    margin: 5rem auto;
    width: fit-content;
    max-width: 90%;
    height: fit-content;
    max-height: 80%;
    background-color: theme.$color_modal-bg;
    border-radius: 1rem;

    .custom-modal-header {
        h2 {
            font-size: 1.5rem;
        }
    }

    .custom-modal-footer {
        margin-top: 1rem;
    }
}
