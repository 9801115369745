.ad-messaging-container {
  .ad-messaging-header {
    display: flex;
    align-items: bottom;
    justify-content: space-between;
    margin-bottom: 0.5rem;

    .ad-messaging-header-dd {
      label {
        display: none;
      }
    }
  }
}
