.locale-details-container {
    text-align: center;
    width: 100%;
    margin-left: 0;

    .locale-header {
        text-align: center;
        .btn-show-locales {
            color: blue;
            margin: 0;
        }
        margin-bottom: 0.2rem;
    }

    .locale-details-content {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 1rem;
    }
}
