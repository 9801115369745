@use '@publicismedia-ds/ui-theme' as theme;

.text-list-container {
    width: 100% !important;
    .text-list-input {
        width: 100%;
        max-width: 100%;
        display: flex;
        gap: 1rem;
        align-items: flex-end;

        .text-input {
            min-width: 70%;
            padding: 0;
            margin-right: 0;
        }
    }

    .duplicate-error-message {
        margin-top: 0.5rem;
        padding-left: 1rem;
        font-size: 0.8rem;
        color: red;
    }

    .icon-add {
        align-self: flex-end;
    }

    button {
        margin: 0;
    }

    .text-list-content {
        min-width: 100%;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        gap: 1rem;
        margin-top: 0.5rem;
    }
}
