.accordion-group {
    width: 550px;
    margin-left: 0.5rem;

    h3 {
        text-transform: capitalize;
    }

    .details-header {
        text-transform: capitalize;
        width: 100%;
        display: flex;
        justify-content: space-between;
        font-size: 1.2rem;

        .action-buttons {
            padding-right: 1rem;
            i {
                margin-left: 1rem;
                cursor: pointer;
            }
        }
    }

    .group-details-header {
        text-transform: capitalize;
    }

    .pmds-accordion-content.pmds-accordion-content__open {
        padding: 0 0.2rem;
    }

    .accordion-group-items {
        min-width: inherit;

        .button-add {
            margin-bottom: 2rem;
        }

        .pmds-accordion-control {
            height: 3.5rem;
            margin-bottom: 0.2rem;

            & > * {
                height: inherit;
            }
        }
        .pmds-accordion-content__padding {
            padding-top: 0;
        }
        .accordion-group-item {
            display: flex;
            flex-wrap: wrap;
            min-height: 5rem;
            padding: 1rem;
            gap: 1rem;

            .locale-property {
                font-weight: bold;
            }

            &.accordion-content {
                background-color: white;

                .locale-details-header {
                    width: 100%;
                }
            }

            &.column {
                flex-direction: column;
            }
        }
    }
}
