@use '@publicismedia-ds/ui-theme' as theme;

.dashboard-filters-container {
  position: relative;
  margin-top: -1rem;
  margin-bottom: 1rem;

  .dashboard-filters-header {
    display: flex;
    align-items: center;

    .filters-preview {
      color: theme.$color_gray-5;
    }
  }

  .dashboard-filters-content {
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;

    .dashboard-filters-card {
      border: 1px solid theme.$color_gray-9;
      width: 100%;
      border-width: 2px;
      z-index: 20;
      box-shadow: 0px 0px 8px 1px theme.$color_gray-9;

      .card-caret {
        display: inline-block;
        position: relative;
        height: 1.75rem;
        width: 20px;
        transform: translate(20px, -1.75rem);
        border-bottom: 2px solid white;
        box-sizing: border-box;

        .icon-chevron-up {
          position: absolute;
          top: 8px;
          left: -4px;
          color: theme.$color_gray-9;
          font-size: 1.75rem;
          height: 18px;
          width: 24px;
          box-sizing: border-box;
          margin: 0;
          padding: 0;
        }
        z-index: 10000;
      }

      .pmds-cards__content {
        overflow-y: visible;
        max-width: 100%;

        .dashboard-filters-form {
          max-width: 100%;
        }
      }

      .pmds-cards__headerContainer {
        padding: 0;
        height: 0;

        .pmds-cards__close {
          transform: translateY(-14px);

          .pmds-tooltip.pmds-tooltip__below-align-center .pmds-tooltip__title,
          .pmds-tooltip.pmds-tooltip__above-align-center .pmds-tooltip__title {
            display: none;
          }

          .pmds-tooltip .pmds-tooltip__caret {
            display: none;
          }
        }
      }
    }
  }
}
