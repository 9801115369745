.study-details-container {
    .study-details-row {
        width: 100%;
        display: flex;

        &.accordion-groups {
            display: block;
            margin-top: 2rem;
        }
    }

    .study-details-locales {
        width: 100%;
    }
    .study-details-groups {
        margin-top: 1rem;
    }
}
