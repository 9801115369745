.word-cloud-container {
    position: relative;
    margin-top: 2rem;

    .word-cloud-cover {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        display: none;
    }

    .word-cloud-header {
        .word-cloud-label {
            margin: 0rem 0 0.5rem 0.5rem;
            font-size: 1.155rem;
            font-family: Roboto, Arial, Helvetica, sans-serif;
            font-weight: 500;
        }

        .word-cloud-dd {
            margin: 0 0 1rem 0.5rem;

            label {
                display: none;
            }
        }
    }

    .data-not-found {
        padding-top: 3rem;
        font-size: 1.57rem;
        text-align: center;
        font-weight: 400;
        color: rgb(127, 127, 127);
        font-family: Roboto, Arial, Helvetica, sans-serif;
    }
}
