@use '@publicismedia-ds/ui-theme' as theme;

$classNameComponent: 'radial-bar-group';

.#{$classNameComponent}-container {
  flex: 1 1 0;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  overflow: hidden;
  border: 1px solid theme.$color_gray-9;
  border-radius: 4px;
  padding: 1rem 1rem 0 1rem;

  .#{$classNameComponent}-header {
    position: relative;
    font-size: 1.125rem;
    font-weight: 500;

    .#{$classNameComponent}-header-text {
      display: inline-block;
      margin-bottom: 0.5rem;
    }

    .pmds-dd {
      label {
        display: none;
      }
    }

    .apexcharts-toolbar {
      top: -4px;
      right: 0;
    }
  }

  .#{$classNameComponent}-content {
    .#{$classNameComponent}-item {
      margin: 0 auto;
      max-width: 250px;
    }
  }
}
