@use '@publicismedia-ds/ui-theme' as theme;

.login-container {
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  background-image: url('../../assets/img/divider-no-arrow.jpg');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 100vw;

  .login-content {
    position: absolute;
    top: 50%;
    left: 0;
    padding: 2.5rem 0;
    max-width: 100vw;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.9);
    transform: translateY(-50%);
    justify-content: space-evenly;

    .login-logo-container {
      display: flex;
      justify-content: center;

      .brand-name-title {
        font-size: 3rem;
        text-align: center;
      }
    }

    .login-content-text {
      max-width: 450px;
      margin: 1.5rem auto;
      margin-bottom: 1rem;
      padding-left: 1rem;
      line-height: 1.2rem;
      font-size: 0.9rem;
      color: #7c7c81;
    }

    .login-error-message {
      display: inline-block;
      margin: auto;
      margin-top: 1rem;
      padding: 1rem 3rem;
      border-radius: 1.5rem;
      font-size: 1rem;
      color: rgb(170, 46, 83);
      background-color: pink;
    }

    .login-content-form {
      .login-inputs {
        display: flex;
        justify-content: center;
        margin-bottom: 1rem;
        gap: 1rem;
      }
      div.pmds-txt {
        margin: 0;
        width: 300px;
      }

      label {
        text-align: start;
        color: black;
      }

      button {
        margin: 0;
        align-self: flex-end;
      }
    }

    .forgot-password {
      margin: 0.25rem 0 0 0.25rem;
      font-weight: bold;
    }
  }
}
