.page-container {
  padding: 4rem 3rem 2rem 3rem;
  max-width: 100%;

  .page-container-title {
    margin-top: 1rem;
    margin-bottom: 1rem;
    text-align: center;
    font-size: 2rem;
    text-transform: capitalize;
  }
}
