.studies-data-table {
    margin-top: 2rem;
    .studies-data-table-above {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;

        .data-table-action-buttons {
            display: flex;
            gap: 1rem;

            .pmds-btn {
                margin: 0;
            }
        }

        &.search-studies-only {
            justify-content: flex-end;
        }
    }

    .pmds-dt {
        .pmds-dd {
            min-width: 120px;
        }
    }
}
