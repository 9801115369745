.account-details-container {
    max-width: 100%;
    .account-details-content {
        .account-details-row {
            margin: 1.5rem 0;

            .account-details-data {
                max-width: 90vw;
            }
        }
    }
}
