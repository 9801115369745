@use '@publicismedia-ds/ui-theme' as theme;

.edit-group-container.custom-modal-container {
    margin-top: 2rem;
    width: 70%;
    padding: 1rem 1.5rem;

    .edit-group-header {
        h2 {
            font-size: 1.5rem;
            margin: 1rem 0;
            text-transform: capitalize;
        }

        .error-message {
            margin-top: 1rem;
            padding: 0.5rem 0;
            background-color: pink;
            color: red;
        }
    }

    .edit-group-content {
        form {
            margin-top: 1rem;
            display: flex;
            justify-content: space-between;
            text-align: left;

            .edit-group-item-container {
                width: 49%;

                .add-new-group-item-inputs {
                    display: flex;
                    align-items: baseline;
                    button {
                        margin: 0.2rem 0;
                        border-color: rgb(0, 140, 0);
                        background-color: rgb(0, 140, 0);

                        &:hover {
                            opacity: 0.95;
                        }
                    }
                    .new-group-item-input {
                        margin: 0.5rem;
                        margin-left: 0;
                    }
                }

                .new-group-item {
                    font-size: 0.9rem;
                    display: inline-block;
                    min-width: fit-content;
                    padding: 0.4rem 0.8rem;
                    margin-right: 0.5rem;
                    margin-top: 0.5rem;
                    background-color: #ffffffbb;
                    border: 2px solid theme.$color_brand-1;
                    border-radius: 2rem;

                    .remove-new-group-item {
                        display: inline-block;
                        margin-right: 0.4rem;
                        padding: 0.2rem 0.2rem 0.1rem 0.2rem;
                        font-size: 0.95rem;
                        color: theme.$color_brand-1-darker;

                        &:hover {
                            cursor: pointer;
                        }
                    }
                }
            }

            .edit-group-item-field {
                margin-top: 0.5rem;
                border: 1px solid lightblue;
                border-radius: 0.4rem;
                min-width: 40%;
                height: 15rem;
                overflow: auto;

                .added-group-items-content {
                    display: flex;
                    flex-wrap: wrap;
                    gap: 0.5rem;
                    justify-content: space-evenly;
                    padding: 0.5rem;
                }
            }

            .pmds-fs {
                align-items: center;
                padding: 0.5rem;
                overflow-y: auto;
                height: fit-content;
                min-height: 10rem;
                max-height: 10rem;
                margin: 0;
            }
        }
    }
    .edit-group-footer {
        align-self: baseline;
    }
}
