.scheduled-report-container {
    .scheduled-report-header {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        gap: 0.5rem;

        i {
            color: #0ebcff;
            padding: 0.3rem;
            font-size: 0.9rem;
            opacity: 1;
            transition: opacity 0.125s;

            &:hover {
                opacity: 0.7;
            }
        }
    }

    .pmds-dd__multi-values {
        .pmds-dd__multi-values-clear {
            display: none;
        }
    }
}
