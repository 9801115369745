@use 'reset-css';
@use '@publicismedia-ds/ui-theme' as theme;

//Only needed once
@include theme.font_importFonts; //Import Roboto font from Google
@include theme.font_typography; //Base styles for various tags
@include theme.icon_importIcons; //Import icon font from hosted url
@include theme.color_paletteClasses; //Mixin adds color classes for color and background-color

html,
body {
  height: 100vh;
  max-width: 100vw;

  //TODO: Global app styles should go here, all other styles should be specific to individual pages or components in their own stylesheets
  #root {
    height: 100%;

    main {
      background-color: #ffffffdd;
    }

    .border-bottom {
      padding-bottom: 1rem;
      border-bottom: 1px solid #d7d7d7;
    }

    .text-muted {
      color: theme.$color_gray-6;
    }

    .text-small {
      font-size: 0.9rem;
    }

    button.pmds-btn.pmds-btn__text.pmds-btn.pmds-btn__text.pmds-btn__brand-1 {
      color: blue;
    }

    div.pmds-txt {
      label {
        color: black !important;
      }
    }

    i {
      &:hover {
        cursor: pointer;
      }
    }

    .actions-link {
      visibility: inherit;
      border: none;
      text-decoration: none;
      color: theme.$color_brand-1-darker;
      i {
        color: theme.$color_brand-1-darker;
      }
    }

    .back-btn {
      float: left;
      padding: 0;
      color: theme.$color_brand-1-darker;
      background-color: transparent;
      border: none;
      font-size: 0.9rem;
      border-bottom: 1px solid theme.$color_brand-1-darker;
      opacity: 1;
      transition: opacity 0.125s;

      &:hover {
        cursor: pointer;
        opacity: 0.6;
      }
    }

    div.pmds-dd {
      label {
        color: black !important;
      }

      .pmds-dd__multi-values {
        li {
          button {
            background-color: white !important;
          }
        }
      }
    }

    .pmds-dt {
      &.no-vertical-scroll {
        .pmds-dt-table {
          max-height: fit-content;
          &.pmds-dt-table__scroll {
            overflow: visible;
          }
        }
      }
      .pmds-dt-table {
        .visible-actions {
          a {
            visibility: visible;
            color: theme.$color_brand-1-darker;
          }
        }
        .pmds-dt-td {
          &.status-column .pmds-dt-td__children {
            position: relative;
            overflow: visible;
          }
          &.currency {
            .pmds-dt-td__children::before {
              content: '$';
            }
          }
          &[data-type='percent'] {
            .pmds-dt-td__children {
              &::after {
                content: '%';
              }
            }
          }
        }
      }
    }

    .pmds-fs__legend {
      &.pmds-fs__legend-invert {
        color: black !important;
      }
    }

    div.pmds-txt__input-wrap input {
      min-width: 100%;
    }

    .pmds-row {
      margin-top: 1.25rem;
    }

    $min-column-size: 200px;
    .pmds-grid {
      gap: 1.25rem;
      grid-template-rows: auto;
      max-width: 100%;
      .pmds-row {
        margin-top: 0;
        &.cols-3 {
          grid-template-columns: repeat(
            auto-fill,
            minmax(max($min-column-size, 100%/4), 1fr)
          );
        }
        &.cols-4 {
          grid-template-columns: repeat(
            auto-fill,
            minmax(max($min-column-size, 100%/5), 1fr)
          );
        }
        &.cols-5 {
          grid-template-columns: repeat(
            auto-fill,
            minmax(max(150px, 100%/6), 1fr)
          );
        }
      }

      .pmds-col {
        gap: 0;
        .pmds-txt {
          max-width: none;
          margin: 0;
        }

        .pmds-chk {
          margin: 0;
        }

        .pmds-dd {
          margin: 0 !important;
        }

        .pmds-dp {
          margin: 0;
        }

        .pmds-fs {
          margin: 0;

          .pmds-fs__children {
            gap: 1rem;
          }
        }

        .react-datepicker-wrapper {
          width: 100%;
          margin: 0;

          .pmds-txt {
            width: 100%;
            max-width: none;
            margin: 0;

            .pmds-txt__input-wrap {
              width: 100%;
            }
          }
        }
      }
    }

    .input-percentage::after {
      content: '%';
      position: absolute;
      top: 37px;
      right: 45px;
    }

    .form-buttons {
      margin-top: 1rem;
      display: flex;
      justify-content: center;

      .btn-cancel {
        font-size: 1rem;

        div {
          color: whitesmoke;
        }
      }
    }

    .apexcharts-menu-item.exportSVG {
      display: none;
    }

    .pmds-chart-apex__icon {
      .icon-export {
        opacity: 0.5;
        &::before {
          content: url('../src/assets/svg/menu.svg');
        }

        &:hover {
          opacity: 0.7;
        }
      }
    }

    .input-error {
      margin-top: 0.125rem;
      margin-left: 0.125rem;
      color: red;
      font-size: 0.8rem;
    }
  }

  .page-title {
    margin: 1rem 0;
    text-align: center;
    font-size: 3rem;
  }

  .react-datepicker__day--disabled {
    color: #c3c3c3 !important;
  }
}
