.content-analysis-container {
    .content-analysis-header {
        display: block;
        font-size: 1.125rem;
        font-weight: 500;
        margin: 1.5rem 0;
    }
    .content-analysis-table {
        .static-width {
            .pmds-dt-td__children {
                width: 250px;
                white-space: normal;
            }
        }
    }
}
