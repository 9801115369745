@use '@publicismedia-ds/ui-theme' as theme;

.tooltips-button-container {
  position: absolute;
  top: 56px;
  right: 0;
  display: flex;
  justify-content: flex-end;
  box-sizing: border-box;
  z-index: 100;

  .tooltips-button {
    margin-top: 0.75rem;
    margin-right: 2.5rem;
    button {
      margin: 0;
      padding: 0;
      border: none;
      background: none;
      font-size: 0.95rem;
      color: theme.$color_brand-1-darker;

      &:hover {
        cursor: pointer;
        color: theme.$color-brand-1-lighter;
      }
    }
  }
}
