.average-position-container {
  .average-position-header {
    display: flex;
    align-items: bottom;
    justify-content: space-between;
    margin-bottom: 1rem;

    .average-position-header-dd {
      label {
        display: none;
      }
    }
  }

  .average-position-content {
    margin-top: 1rem;
    .average-position-difference {
      font-size: 1.2rem;
    }
  }
}
