.alerts-data-table {
    margin-top: 2rem;
    .alerts-data-table-above {
        display: flex;
        width: 100%;
        justify-content: space-between;

        .data-table-action-buttons {
            display: flex;
            gap: 1rem;

            .pmds-btn {
                margin: 0;
            }
        }
    }

    .pmds-dt {
        button.data-table-study-name {
            visibility: visible;
            border: none;
            font-size: 0.9rem;
            background: none;
            text-decoration: underline;
            color: blue;

            &:hover {
                cursor: pointer;
            }
        }
    }
}
