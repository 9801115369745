.alert-content {
    max-width: 350px;
    font-size: 1rem;
    padding-top: 3rem;
    margin-bottom: -2rem;

    .alert-message {
        margin: 1rem 0 !important;

        &:first-child {
            margin-top: 0 !important;
        }
    }
}
