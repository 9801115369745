.radial-bar-compare-container {
  position: relative;

  .radial-bar-compare-header {
    display: flex;
    align-items: bottom;
    justify-content: space-between;
    margin-bottom: 1rem;

    .radial-bar-compare-header-dd {
      label {
        display: none;
      }
    }
  }

  .radial-bar-compare-content {
    margin-top: 1rem;
    .radial-bar-compare-difference {
      font-size: 1.2rem;
    }
  }
}
